import { colors } from "./colors";

export const heatmapData = [
  {
    label: "CO",
    id: "US-CO",
    rate: 0.25,
  },
  { label: "ID", id: "US-ID", rate: 0.18, settings: { fill: colors["white"] } },
  { label: "SD", id: "US-SD", rate: 0.24, settings: { fill: colors["white"] } },
  { label: "NE", id: "US-NE", rate: 0.46, settings: { fill: colors["white"] } },
  { label: "KS", id: "US-KS", rate: 0.25 },
  { label: "OK", id: "US-OK", rate: 0.21 },
  { label: "TX", id: "US-TX", rate: 0.5 },
  { label: "NM", id: "US-NM", rate: 0.23 },
  { label: "AZ", id: "US-AZ", rate: 0.26 },
  { label: "NV", id: "US-NV", rate: 0.43 },
  { label: "UT", id: "US-UT", rate: 0.48 },
  { label: "CA", id: "US-CA", rate: 0.5 },
  { label: "OR", id: "US-OR", rate: 0.25 },

  { id: "US-CT", label: "CT", rate: 0.32 },
  { id: "US-ME", label: "ME", rate: 0.23 },
  { id: "US-MA", label: "MA", rate: 0.41 },
  { id: "US-NH", label: "NH", rate: 0.27 },
  { id: "US-NJ", label: "NJ", rate: 0.29 },
  { id: "US-NY", label: "NY", rate: 0.54 },
  { id: "US-PA", label: "PA", rate: 0.36 },
  { id: "US-RI", label: "RI", rate: 0.39 },
  { id: "US-VT", label: "VT", rate: 0.45 },

  { id: "US-IL", label: "IL", rate: 0.33 },
  { id: "US-IN", label: "IN", rate: 0.19 },
  { id: "US-IA", label: "IA", rate: 0.41 },
  { id: "US-KS", label: "KS", rate: 0.25 },
  { id: "US-MI", label: "MI", rate: 0.2 },
  { id: "US-MN", label: "MN", rate: 0.24 },
  { id: "US-MO", label: "MO", rate: 0.23 },
  { id: "US-NE", label: "NE", rate: 0.46 },
  { id: "US-ND", label: "ND", rate: 0.16 },
  { id: "US-OH", label: "OH", rate: 0.21 },
  { id: "US-SD", label: "SD", rate: 0.24 },
  { id: "US-WI", label: "WI", rate: 0.22 },

  { id: "US-AK", label: "AK", rate: 0.17 },
  { id: "US-AZ", label: "AZ", rate: 0.26 },
  { id: "US-CA", label: "CA", rate: 0.3 },
  { id: "US-CO", label: "CO", rate: 0.25 },
  { id: "US-HI", label: "HI", rate: 0.26 },
  { id: "US-ID", label: "ID", rate: 0.18 },
  { id: "US-MT", label: "MT", rate: 0.17 },
  { id: "US-NV", label: "NV", rate: 0.43 },
  { id: "US-NM", label: "NM", rate: 0.23 },
  { id: "US-OR", label: "OR", rate: 0.24 },
  { id: "US-UT", label: "UT", rate: 0.48 },
  { id: "US-WA", label: "WA", rate: 0.28 },
  { id: "US-WY", label: "WY", rate: 0.16 },

  { id: "US-AL", label: "AL", rate: 0.25 },
  { id: "US-AR", label: "AR", rate: 0.25 },
  { id: "US-DE", label: "DE", rate: 0.15 },
  { id: "US-FL", label: "FL", rate: 0.34 },
  { id: "US-GA", label: "GA", rate: 0.31 },
  { id: "US-KY", label: "KY", rate: 0.17 },
  { id: "US-LA", label: "LA", rate: 0.26 },
  { id: "US-MD", label: "MD", rate: 0.21 },
  { id: "US-MS", label: "MS", rate: 0.35 },
  { id: "US-NC", label: "NC", rate: 0.25 },
  { id: "US-OK", label: "OK", rate: 0.21 },
  { id: "US-SC", label: "SC", rate: 0.28 },
  { id: "US-TN", label: "TN", rate: 0.32 },
  { id: "US-TX", label: "TX", rate: 0.28 },
  { id: "US-VA", label: "VA", rate: 0.2 },
  { id: "US-DC", label: "DC", rate: 0.52 },
  { id: "US-WV", label: "WV", rate: 0.14 },
  { id: "US-PR", label: "PR", rate: 0.06 },
];

export function formatPercent(n: number | undefined): string {
  if (!n) {
    return "";
  }

  return Math.round(n * 100) + "%";
}

export function calculateColor(value: number) {
  let color = null;
  if (value >= 0.04) {
    //4-16
    color = colors["red-4"];
  }
  if (value >= 0.17) {
    //17-20
    color = colors["red-3"];
  }
  if (value >= 0.21) {
    //21-25
    color = colors["red-2"];
  }
  if (value >= 0.26) {
    color = colors["red"];
  }
  return color;
}
